.key-page-wrapper {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-grow: 1;
  flex-shrink: 0;
}
