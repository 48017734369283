.textarea-container .textarea-input {
  display: block;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}
.textarea-container .textarea-input.read-only {
  border: 1px solid transparent;
  padding-left: 3px;
}
.textarea-container .textarea-input:disabled {
  border: 1px solid transparent;
  padding-left: 10px;
}
