.partitions-list-container {
  padding: 20px 25px;
  margin: 0;
}
.partitions-list-container .add-partition-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  margin-left: 5px;
}
.partitions-list-container .add-partition-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.partitions-list-container .add-partition-button:disabled {
  background-color: gray;
}
.partitions-list-container .add-partition-button:disabled:hover,
.partitions-list-container .add-partition-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.partitions-list-container .add-default-partition-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  background-color: transparent;
  color: gray;
  margin-left: 20px;
}
.partitions-list-container .add-default-partition-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.partitions-list-container .add-default-partition-button:disabled {
  background-color: gray;
}
.partitions-list-container .add-default-partition-button:disabled:hover,
.partitions-list-container .add-default-partition-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.partitions-list-container .add-default-partition-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.partitions-list-container .add-default-partition-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.partitions-list-container .add-default-partition-button:disabled {
  background-color: transparent;
}
.partitions-list-container .add-default-partition-button:disabled:hover,
.partitions-list-container .add-default-partition-button:disabled:active {
  cursor: default;
  background-color: transparent;
}
.partitions-accordion-container {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 70px;
}
.partitions-accordion-container-item-title {
  background-color: #fafafa;
  border-top: 1px solid #ccc;
  color: #333;
  padding: 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.partitions-accordion-container-item-title:hover {
  background-color: #eeeeee;
}
.partitions-accordion-container-item-title .partitions-accordion-container-item-title-details {
  flex-grow: 1;
  margin-left: 10px;
  font-size: 1em;
  color: #999999;
  width: 100vh;
}
.partitions-accordion-container-item-title .expander-icon {
  margin-right: 10px;
  font-size: 18px;
  font-family: 'tweekIcons';
  color: darkgray;
  background-color: transparent;
  outline: none;
  border: 1px solid transparent;
  border-radius: 50%;
  content: '&#xE908;';
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
}
.partitions-accordion-container-item-title .expander-icon:hover {
  cursor: pointer;
  color: gray;
  border: 1px solid lightgray;
}
.partitions-accordion-container-item-title .expander-icon:active {
  color: #676767;
  border: 1px solid gray;
}
.partitions-accordion-container-item:first-child .partitions-accordion-container-item-title {
  border-top: none;
}
.partitions-accordion-container-item .ReactCollapse--collapse {
  transition: all 0.3s;
}
.partitions-accordion-container-item-expanded .partitions-accordion-container-item-title {
  background-color: #03a9f4;
  color: #fff;
}
.partitions-accordion-container-item-expanded .partitions-accordion-container-item-title:hover {
  background-color: #03b2ff;
}
.partitions-accordion-container-item-expanded .partitions-accordion-container-item-title-details {
  color: lightgrey;
}
.partitions-accordion-container-item-expanded .expander-icon {
  color: white;
  transform: rotate(0deg);
}
.partitions-accordion-container-item-expanded .expander-icon:hover {
  color: white;
  border: 1px solid lightgray;
}
.partitions-accordion-container-item-expanded .expander-icon:active {
  color: white;
  border: 1px solid gray;
}
.partitions-accordion-container-item-body-wrapper {
  color: #666;
  padding: 20px;
  position: relative;
}
.new-partition-container {
  width: 100%;
  box-sizing: border-box;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-bottom: 0;
  color: #333;
  padding: 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}
.new-partition-container .add-partition-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.new-partition-container .add-partition-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.new-partition-container .add-partition-button:disabled {
  background-color: gray;
}
.new-partition-container .add-partition-button:disabled:hover,
.new-partition-container .add-partition-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.new-partition-container .add-partition-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.new-partition-item-container {
  margin-right: 15px;
}
