.context-page-container {
  background-color: #eee;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.context-page-container .context-page {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  box-sizing: content-box;
  margin: 20px;
  display: block;
  width: 900px;
}
.context-page-container .context-page .horizontal-separator {
  background: lightgrey;
  height: 1px;
  margin: 20px 0;
}
