.fixed-keys-container .add-key-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.fixed-keys-container .add-key-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.fixed-keys-container .add-key-button:disabled {
  background-color: gray;
}
.fixed-keys-container .add-key-button:disabled:hover,
.fixed-keys-container .add-key-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.fixed-keys-container .add-key-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
