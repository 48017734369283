* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.select-key-message {
  position: absolute;
  width: 300px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  font-size: 28px;
  color: rgba(0, 122, 204, 0.6);
}
h3 {
  font-size: 22px;
  color: #515c66;
  margin-left: 4px;
  margin-bottom: 4px;
}
.validation-icon-wrapper i {
  background-image: url('../resources/alert-icon.svg');
  width: 16px;
  height: 16px;
  display: inline-block;
}
