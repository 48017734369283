.key-tags .tag {
  cursor: pointer !important;
}
.key-tags .tags-container:before {
  content: 'Tags';
  color: #a5a5a5;
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.key-tags .tags-container .tag {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
}
.key-tags .tags-container .tag .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.key-tags .tags-container .tag .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.key-tags .tags-container .tag-input {
  display: inline-block;
  font-size: 12px;
}
.key-tags .tags-container .tag-input input,
.key-tags .tags-container .tag-input input:focus {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
  outline: none;
}
.key-tags .tags-container .tag-input input .tag-delete-button,
.key-tags .tags-container .tag-input input:focus .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.key-tags .tags-container .tag-input input .tag-delete-button:hover,
.key-tags .tags-container .tag-input input:focus .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.key-tags .tags-container .tags-suggestion {
  position: fixed;
  z-index: 1;
}
.key-tags .tags-container .tags-suggestion ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
  position: fixed;
  max-height: 300px;
  overflow-y: auto;
}
.key-tags .tags-container .tags-suggestion ul li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
.key-tags .tags-container .tags-suggestion ul li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.key-tags .tags-container .tags-suggestion ul li:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
.key-tags .tags-container .tags-suggestion ul li:active,
.key-tags .tags-container .tags-suggestion ul li.active:active {
  cursor: pointer;
  background-color: #d9d9d9;
}
.key-tags .tags-container .tags-suggestion ul li.active {
  cursor: pointer;
  background-color: #eeeeee;
}
.key-tags .tags-container .tag-input input,
.key-tags .tags-container .tag-input input:focus {
  padding: 0px 0px 0px 5px;
  margin: 3px 0px 0px 0px;
}
