.identity-page *[data-comp='property-item'] {
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 30px;
  padding: 8px 4px 8px 16px;
}
.identity-page *[data-comp='property-item'] input {
  margin-right: 10px;
  font-size: 12px;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove'] {
  margin-right: 2px;
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
  font-size: 12px;
  padding-top: 1px;
  margin-top: 7px;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:disabled {
  background-color: gray;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:disabled:hover,
.identity-page *[data-comp='property-item'] button[data-comp='remove']:disabled:active {
  cursor: default;
  background-color: gray;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:hover {
  background-color: #9c9c9c;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:active {
  background-color: #7b7b7b;
}
.identity-page *[data-comp='property-item'] button[data-comp='remove']:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.identity-page *[data-comp='new-property-item'] {
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 30px;
  padding: 8px 4px 8px 16px;
  border: 1px #999999 dotted;
  border-radius: 3px;
  background-color: #efffff;
  width: fit-content;
  padding-left: 28px;
}
.identity-page *[data-comp='new-property-item'] input {
  margin-right: 10px;
  font-size: 12px;
}
.identity-page *[data-comp='new-property-item'] button[data-comp='add'] {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
  margin-top: 4px;
  margin-left: 15px;
}
.identity-page *[data-comp='new-property-item'] button[data-comp='add']:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.identity-page *[data-comp='new-property-item'] button[data-comp='add']:disabled {
  background-color: gray;
}
.identity-page *[data-comp='new-property-item'] button[data-comp='add']:disabled:hover,
.identity-page *[data-comp='new-property-item'] button[data-comp='add']:disabled:active {
  cursor: default;
  background-color: gray;
}
.identity-page *[data-comp='new-property-item'] button[data-comp='add']:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.identity-page button[data-comp='advanced'] {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  margin-left: -30px;
  z-index: 3;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  padding: 0px;
}
.identity-page button[data-comp='advanced']:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.identity-page button[data-comp='advanced']:disabled {
  background-color: gray;
}
.identity-page button[data-comp='advanced']:disabled:hover,
.identity-page button[data-comp='advanced']:disabled:active {
  cursor: default;
  background-color: gray;
}
.identity-page .simple-property {
  flex-direction: row;
  display: flex;
}
.identity-page .text-input {
  padding: 0px;
}
