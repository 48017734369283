.dependency-indicator-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #a5a5a5;
  font-size: 14px;
  margin-top: 10px;
  padding-left: 4px;
}
.dependency-indicator-container .ReactCollapse--collapse {
  transition: all 0.3s;
}
.dependency-indicator-container .dependency-item {
  list-style-type: none;
  font-weight: 300;
  color: #a5a5a5;
}
.dependency-indicator-container .dependency-item a {
  text-decoration: none;
  color: #a5a5a5;
}
.dependency-indicator-container .dependency-item a:visited {
  color: #a5a5a5;
}
.dependency-indicator-container .dependency-item a:hover {
  color: #727272;
}
.dependency-indicator-container .dependency-item a:active {
  color: #a5a5a5;
}
.dependency-indicator-container .dependency-item [data-comp='delete-alias'] {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background: none;
}
.dependency-indicator-container .dependency-item [data-comp='delete-alias']:disabled {
  cursor: default;
}
.dependency-indicator-container .dependency-item [data-comp='delete-alias']:hover:not(:disabled) {
  background: rgba(215, 215, 215, 0.49);
}
.dependency-indicator-container .dependency-item [data-comp='delete-alias'] img {
  width: 22px;
}
