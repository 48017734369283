.key-folder {
  width: 100%;
}
.key-folder .key-folder-name {
  font-size: 16px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  padding-left: 5px;
  padding-top: 13px;
  color: #d8d8d8;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}
.key-folder .key-folder-name:hover {
  cursor: pointer;
  background-color: #363a3e;
}
.key-folder .key-folder-name:hover .add-key {
  display: inline-block;
}
.key-folder .key-folder-name:active {
  background-color: #363a3e;
}
.key-folder .number-of-folder-keys {
  font-size: 11px;
  display: flex;
  margin-left: 5px;
  margin-top: 5px;
  opacity: 0.7;
}
.key-folder .number-of-folder-keys:hover {
  cursor: pointer;
}
.key-folder .key-folder-icon {
  width: 16px;
  margin-right: 5px;
  margin-bottom: 10px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
}
.key-folder .key-folder-icon:hover {
  cursor: pointer;
}
.key-folder .folder-items {
  display: flex;
  flex-grow: 1;
}
.key-folder .sub-tree {
  display: flex;
  flex-grow: 1;
}
.key-folder .add-key {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
  display: none;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}
.key-folder .add-key:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.key-folder .add-key:disabled {
  background-color: gray;
}
.key-folder .add-key:disabled:hover,
.key-folder .add-key:disabled:active {
  cursor: default;
  background-color: gray;
}
.key-folder .add-key:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
