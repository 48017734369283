.text-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
}
.text-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.text-input:disabled {
  border-color: #bcbcbc;
}
