.rule-value-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
}
.rule-value-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.rule-value-button:disabled {
  background-color: gray;
}
.rule-value-button:disabled:hover,
.rule-value-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.inputValue input,
.inputValue .bootstrap-typeahead .bootstrap-typeahead-input {
  width: 150px;
}
.inputValue .bootstrap-typeahead .dropdown-menu {
  min-width: 150px;
}
.inputValue .values-input {
  resize: none;
  display: inline-block;
}
.rule-value-container .add-variant-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
}
.rule-value-container .add-variant-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.rule-value-container .add-variant-button:disabled {
  background-color: gray;
}
.rule-value-container .add-variant-button:disabled:hover,
.rule-value-container .add-variant-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.rule-value-container .to-feature-flag-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
}
.rule-value-container .to-feature-flag-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.rule-value-container .to-feature-flag-button:disabled {
  background-color: gray;
}
.rule-value-container .to-feature-flag-button:disabled:hover,
.rule-value-container .to-feature-flag-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.identity-selection-container {
  display: flex;
}
.identity-selection-container .identity-selection-title {
  font-size: 12px;
  color: darkgray;
  display: flex;
  margin-top: 7px;
}
.identity-selection-container .identity-selection-combobox-wrapper {
  display: flex;
  display: inline-block;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-input {
  flex-grow: 1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 150px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li a,
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li a:first-child,
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead {
  flex-grow: 1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 150px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li a,
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li a:first-child,
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-typeahead .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-input {
  flex-grow: 1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.identity-selection-container .identity-selection-combobox-wrapper .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 150px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li a,
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li a:first-child,
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead {
  flex-grow: 1;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 150px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li a,
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li a:first-child,
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.identity-selection-container .identity-selection-combobox-wrapper .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.bernoulli-trial-container {
  display: inline-block;
}
.bernoulli-trial-container .bernoulli-trial-input-wrapper {
  display: inline-block;
  margin-top: 10px;
}
.bernoulli-trial-container .bernoulli-trial-input-wrapper label {
  font-size: 12px;
}
.bernoulli-trial-container .bernoulli-trial-input-wrapper .bernoulli-trial-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  height: 16px;
  font-size: 12px;
  font-size: 14px;
  width: 40px;
}
.bernoulli-trial-container .bernoulli-trial-input-wrapper .bernoulli-trial-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.bernoulli-trial-container .bernoulli-trial-input-wrapper .bernoulli-trial-input:disabled {
  border-color: #bcbcbc;
}
.bernoulli-trial-container .bernoulli-trial-slider-wrapper {
  display: inline-block;
  position: relative;
  bottom: -4px;
  left: 10px;
}
.set-bernoulli-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
  display: inline-block;
  margin-left: 25px;
}
.set-bernoulli-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.set-bernoulli-button:disabled {
  background-color: gray;
}
.set-bernoulli-button:disabled:hover,
.set-bernoulli-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.set-to-true-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
  display: inline-block;
  margin-left: 25px;
}
.set-to-true-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.set-to-true-button:disabled {
  background-color: gray;
}
.set-to-true-button:disabled:hover,
.set-to-true-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.set-to-false-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  margin: 7px;
  opacity: 0.7;
  display: inline-block;
  margin-left: 25px;
}
.set-to-false-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.set-to-false-button:disabled {
  background-color: gray;
}
.set-to-false-button:disabled:hover,
.set-to-false-button:disabled:active {
  cursor: default;
  background-color: gray;
}
