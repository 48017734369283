.context-search-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: -5px;
}
.context-search-container .identity-type-container,
.context-search-container .identity-id-container,
.context-search-container .search-button-container {
  padding: 5px;
}
.context-search-container .identity-type-container .identity-type {
  display: flex;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-input {
  flex-grow: 1;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 200px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li a,
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li a:first-child,
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead {
  flex-grow: 1;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 200px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li a,
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li a:first-child,
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.context-search-container .identity-type-container .identity-type .override-typeahead .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .override-input {
  flex-grow: 1;
}
.context-search-container .identity-type-container .identity-type .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.context-search-container .identity-type-container .identity-type .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.context-search-container .identity-type-container .identity-type .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 200px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li a,
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li a:first-child,
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.context-search-container .identity-type-container .identity-type .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead {
  flex-grow: 1;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 150px;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 200px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li a,
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li a:first-child,
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.context-search-container .identity-type-container .identity-type .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.context-search-container .identity-id-container input {
  width: 350px;
  font-size: 16px;
}
.context-search-container .search-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 12px;
  padding-top: 3px;
}
.context-search-container .search-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.context-search-container .search-button:disabled {
  background-color: gray;
}
.context-search-container .search-button:disabled:hover,
.context-search-container .search-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.context-search-container .search-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
