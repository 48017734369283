.fixed-key-container {
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 16px;
}
.fixed-key-container .delete-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
  font-size: 12px;
  padding-top: 1px;
}
.fixed-key-container .delete-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.fixed-key-container .delete-button:disabled {
  background-color: gray;
}
.fixed-key-container .delete-button:disabled:hover,
.fixed-key-container .delete-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.fixed-key-container .delete-button:hover {
  background-color: #9c9c9c;
}
.fixed-key-container .delete-button:active {
  background-color: #7b7b7b;
}
.fixed-key-container .delete-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.fixed-key-container .editable-key-container {
  display: flex;
}
.fixed-key-container .editable-key-container > div {
  margin: 5px;
}
.fixed-key-container .editable-key-container .key-input .bootstrap-typeahead .bootstrap-typeahead-input {
  width: 350px;
}
.fixed-key-container .editable-key-container .key-input .bootstrap-typeahead .dropdown-menu {
  min-width: 350px;
}
.fixed-key-container .editable-key-container.new-item input {
  color: green;
}
.fixed-key-container .editable-key-container.removed {
  text-decoration: line-through;
}
.fixed-key-container .editable-key-container.removed input {
  text-decoration: line-through;
}
.fixed-key-container .editable-key-container.removed .object-type-expander {
  display: none;
}
.new-fixed-key {
  display: flex;
  align-items: center;
  border: 1px #999999 dotted;
  border-radius: 3px;
  background-color: #efffff;
  width: fit-content;
  padding: 8px 4px 8px 26px;
}
.new-fixed-key .editable-key-container {
  display: flex;
}
.new-fixed-key .editable-key-container > div {
  margin: 5px;
}
.new-fixed-key .editable-key-container .key-input .bootstrap-typeahead .bootstrap-typeahead-input {
  width: 350px;
}
.new-fixed-key .editable-key-container .key-input .bootstrap-typeahead .dropdown-menu {
  min-width: 350px;
}
.new-fixed-key .add-key-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.new-fixed-key .add-key-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.new-fixed-key .add-key-button:disabled {
  background-color: gray;
}
.new-fixed-key .add-key-button:disabled:hover,
.new-fixed-key .add-key-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.new-fixed-key .add-key-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
