.panel {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  box-sizing: content-box;
  margin: 20px;
  display: block;
}
.metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.metro-button:disabled {
  background-color: gray;
}
.metro-button:disabled:hover,
.metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  background-color: transparent;
  color: gray;
}
.transparent-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.transparent-metro-button:disabled {
  background-color: gray;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.transparent-metro-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.transparent-metro-button:disabled {
  background-color: transparent;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: transparent;
}
.small-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
}
.small-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-metro-button:disabled {
  background-color: gray;
}
.small-metro-button:disabled:hover,
.small-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-metro-textbox {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  height: 16px;
  font-size: 12px;
}
.small-metro-textbox:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.small-metro-textbox:disabled {
  border-color: #bcbcbc;
}
.circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
}
.circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.circle-button:disabled {
  background-color: gray;
}
.circle-button:disabled:hover,
.circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
}
.small-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-circle-button:disabled {
  background-color: gray;
}
.small-circle-button:disabled:hover,
.small-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-button {
  background-color: #bcbcbc;
}
.gray-button:hover {
  background-color: #9c9c9c;
}
.gray-button:active {
  background-color: #7b7b7b;
}
.gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
}
.gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.gray-circle-button:disabled {
  background-color: gray;
}
.gray-circle-button:disabled:hover,
.gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-circle-button:hover {
  background-color: #9c9c9c;
}
.gray-circle-button:active {
  background-color: #7b7b7b;
}
.small-gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
}
.small-gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-gray-circle-button:disabled {
  background-color: gray;
}
.small-gray-circle-button:disabled:hover,
.small-gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-gray-circle-button:hover {
  background-color: #9c9c9c;
}
.small-gray-circle-button:active {
  background-color: #7b7b7b;
}
.add-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.add-circle-button:disabled {
  background-color: gray;
}
.add-circle-button:disabled:hover,
.add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.small-add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  font-size: 11px;
  padding-top: 1px;
}
.small-add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-add-circle-button:disabled {
  background-color: gray;
}
.small-add-circle-button:disabled:hover,
.small-add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 12px;
  padding-top: 3px;
}
.search-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.search-circle-button:disabled {
  background-color: gray;
}
.search-circle-button:disabled:hover,
.search-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.search-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
  font-size: 16px;
  padding-top: 2px;
}
.close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.close-circle-button:disabled {
  background-color: gray;
}
.close-circle-button:disabled:hover,
.close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.close-circle-button:hover {
  background-color: #9c9c9c;
}
.close-circle-button:active {
  background-color: #7b7b7b;
}
.close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.small-close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
  font-size: 12px;
  padding-top: 1px;
}
.small-close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-close-circle-button:disabled {
  background-color: gray;
}
.small-close-circle-button:disabled:hover,
.small-close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-close-circle-button:hover {
  background-color: #9c9c9c;
}
.small-close-circle-button:active {
  background-color: #7b7b7b;
}
.small-close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.centered-message {
  position: absolute;
  width: 300px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  font-size: 28px;
  color: rgba(0, 122, 204, 0.6);
}
.edit-external-app-page {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  box-sizing: content-box;
  margin: 20px;
  display: block;
  padding: 35px;
  border-width: 0;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  margin-top: 72px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
}
.edit-external-app-page h3 {
  font-size: 22px;
  padding-left: 10px;
  margin: 0px 0px 58px 0px;
  font-weight: 400;
}
.edit-external-app-page .field-input-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: space-between;
  padding-left: 10px;
  padding-right: 135px;
  margin-bottom: 41px;
}
.edit-external-app-page .field-input-wrapper > input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  width: 550px;
}
.edit-external-app-page .field-input-wrapper > input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.edit-external-app-page .field-input-wrapper > input:disabled {
  border-color: #bcbcbc;
}
.edit-external-app-page .field-label {
  all: unset;
  font-size: 16px;
  margin: 0px 5px 0 0;
  color: gray;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding-bottom: 1px;
}
.edit-external-app-page .permissions-picker {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: space-between;
  padding-left: 10px;
  padding-right: 135px;
  margin-bottom: 41px;
}
.edit-external-app-page .permissions-picker > input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  width: 550px;
}
.edit-external-app-page .permissions-picker > input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.edit-external-app-page .permissions-picker > input:disabled {
  border-color: #bcbcbc;
}
.edit-external-app-page .permissions-picker .tags-container:before {
  content: 'Tags';
  color: #a5a5a5;
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.edit-external-app-page .permissions-picker .tags-container .tag {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
}
.edit-external-app-page .permissions-picker .tags-container .tag .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.edit-external-app-page .permissions-picker .tags-container .tag .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input {
  display: inline-block;
  font-size: 12px;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input input,
.edit-external-app-page .permissions-picker .tags-container .tag-input input:focus {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
  outline: none;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input input .tag-delete-button,
.edit-external-app-page .permissions-picker .tags-container .tag-input input:focus .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input input .tag-delete-button:hover,
.edit-external-app-page .permissions-picker .tags-container .tag-input input:focus .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion {
  position: fixed;
  z-index: 1;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
  position: fixed;
  max-height: 300px;
  overflow-y: auto;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li:active,
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li.active:active {
  cursor: pointer;
  background-color: #d9d9d9;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion ul li.active {
  cursor: pointer;
  background-color: #eeeeee;
}
.edit-external-app-page .permissions-picker .tags-container {
  display: flex;
  max-width: 500px;
  min-width: 150px;
  overflow-y: auto;
  border-bottom: 1px solid rgba(0, 122, 204, 0.6);
  padding: 4px;
  box-sizing: border-box;
}
.edit-external-app-page .permissions-picker .tags-container > div,
.edit-external-app-page .permissions-picker .tags-container .ReactTags__tagInputField {
  min-height: 20px;
}
.edit-external-app-page .permissions-picker .tags-container:before {
  content: '';
  margin: 0;
}
.edit-external-app-page .permissions-picker .tags-container .tag {
  padding: 2px 3px 2px 3px;
  margin: 3px 5px 0 0;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input {
  display: inline-block;
}
.edit-external-app-page .permissions-picker .tags-container .tag-input input,
.edit-external-app-page .permissions-picker .tags-container .tag-input input:focus {
  background-color: transparent;
  width: 100px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.edit-external-app-page .permissions-picker .tags-container .tags-suggestion {
  margin-top: 6px;
}
