.partitions-selector-container {
  display: flex;
}
.partitions-selector-container .partitions-label {
  font-size: 13px;
  margin: 5px 5px 0 0;
  color: gray;
}
.partitions-selector-container .partition-tags-wrapper .tags-container:before {
  content: 'Tags';
  color: #a5a5a5;
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input {
  display: inline-block;
  font-size: 12px;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input,
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input:focus {
  border: 1px solid #ddd;
  background-color: #eeeeee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px 5px 0 0;
  border-radius: 2px;
  color: #515c66;
  outline: none;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input .tag-delete-button,
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input:focus .tag-delete-button {
  display: block;
  float: right;
  margin-top: 1px;
  color: #aaa;
  margin-left: 6px;
  padding-left: 1px;
  padding-bottom: 4px;
  width: 9px;
  height: 9px;
  transition: all 0.2s ease-in-out;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input .tag-delete-button:hover,
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input:focus .tag-delete-button:hover {
  cursor: pointer;
  color: #939393;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion {
  position: fixed;
  z-index: 1;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
  position: fixed;
  max-height: 300px;
  overflow-y: auto;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li:active,
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li.active:active {
  cursor: pointer;
  background-color: #d9d9d9;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion ul li.active {
  cursor: pointer;
  background-color: #eeeeee;
}
.partitions-selector-container .partition-tags-wrapper .tags-container {
  display: flex;
  max-width: 500px;
  min-width: 150px;
  overflow-y: auto;
  border-bottom: 1px solid rgba(0, 122, 204, 0.6);
  padding: 4px;
  box-sizing: border-box;
}
.partitions-selector-container .partition-tags-wrapper .tags-container > div,
.partitions-selector-container .partition-tags-wrapper .tags-container .ReactTags__tagInputField {
  min-height: 20px;
}
.partitions-selector-container .partition-tags-wrapper .tags-container:before {
  content: '';
  margin: 0;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag {
  padding: 2px 3px 2px 3px;
  margin: 3px 5px 0 0;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input {
  display: inline-block;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input,
.partitions-selector-container .partition-tags-wrapper .tags-container .tag-input input:focus {
  background-color: transparent;
  width: 100px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.partitions-selector-container .partition-tags-wrapper .tags-container .tags-suggestion {
  margin-top: 6px;
}
