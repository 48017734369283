.save-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  width: 125px;
}
.save-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.save-button:disabled {
  background-color: gray;
}
.save-button:disabled:hover,
.save-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.save-button[data-state-is-saving='true'],
.save-button[data-state-is-saving='true']:disabled {
  background-color: green;
}
