.schema-page-container {
  display: flex;
  flex-grow: 1;
  background-color: #eee;
}
.schema-page-container .side-menu {
  color: #d8d8d8;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  flex-basis: 400px;
  flex-direction: column;
  background: #414b53;
}
.schema-page-container .side-menu ul {
  display: flex;
  padding-left: 20px;
  flex-direction: column;
}
.schema-page-container .side-menu li {
  flex-direction: column;
  background: #414b53;
  margin: 6px 0;
  display: flex;
}
.schema-page-container .side-menu li > div[data-comp='group'] {
  padding: 10px;
  background-color: #333b41;
}
.schema-page-container .side-menu li a {
  font-size: 16px;
  color: #d8d8d8;
  text-decoration: none;
}
.schema-page-container .side-menu > li {
  margin: 0;
}
.schema-page-container .side-menu [data-comp='add-new-identity'] button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}
.schema-page-container .side-menu [data-comp='add-new-identity'] button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.schema-page-container .side-menu [data-comp='add-new-identity'] button:disabled {
  background-color: gray;
}
.schema-page-container .side-menu [data-comp='add-new-identity'] button:disabled:hover,
.schema-page-container .side-menu [data-comp='add-new-identity'] button:disabled:active {
  cursor: default;
  background-color: gray;
}
.schema-page-container .side-menu [data-comp='add-new-identity'] input {
  font-size: 16px;
  color: #d8d8d8;
}
