.readonly-key-message {
  float: left;
  font-size: 22px;
  color: #888;
}
.key-action-buttons-wrapper {
  display: flex;
  align-items: center;
  float: right;
}
.key-action-buttons-wrapper .save-button {
  margin-left: 10px;
  width: 125px;
}
.key-action-buttons-wrapper .save-button[data-state-is-saving='true'],
.key-action-buttons-wrapper .save-button[data-state-is-saving='true']:disabled {
  background-color: green;
}
.key-action-buttons-wrapper .icon-button {
  background-color: #bcbcbc;
  color: #515151;
  margin-left: 10px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.key-action-buttons-wrapper .icon-button:hover {
  background-color: #9c9c9c;
}
.key-action-buttons-wrapper .icon-button:active {
  background-color: #7b7b7b;
}
.key-action-buttons-wrapper .icon-button:disabled {
  cursor: default;
}
.key-action-buttons-wrapper .icon-button img {
  width: 28px;
}
