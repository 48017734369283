.panel {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  box-sizing: content-box;
  margin: 20px;
  display: block;
}
.metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.metro-button:disabled {
  background-color: gray;
}
.metro-button:disabled:hover,
.metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  background-color: transparent;
  color: gray;
}
.transparent-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.transparent-metro-button:disabled {
  background-color: gray;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.transparent-metro-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.transparent-metro-button:disabled {
  background-color: transparent;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: transparent;
}
.small-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
}
.small-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-metro-button:disabled {
  background-color: gray;
}
.small-metro-button:disabled:hover,
.small-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-metro-textbox {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  height: 16px;
  font-size: 12px;
}
.small-metro-textbox:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.small-metro-textbox:disabled {
  border-color: #bcbcbc;
}
.circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
}
.circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.circle-button:disabled {
  background-color: gray;
}
.circle-button:disabled:hover,
.circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
}
.small-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-circle-button:disabled {
  background-color: gray;
}
.small-circle-button:disabled:hover,
.small-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-button {
  background-color: #bcbcbc;
}
.gray-button:hover {
  background-color: #9c9c9c;
}
.gray-button:active {
  background-color: #7b7b7b;
}
.gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
}
.gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.gray-circle-button:disabled {
  background-color: gray;
}
.gray-circle-button:disabled:hover,
.gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-circle-button:hover {
  background-color: #9c9c9c;
}
.gray-circle-button:active {
  background-color: #7b7b7b;
}
.small-gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
}
.small-gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-gray-circle-button:disabled {
  background-color: gray;
}
.small-gray-circle-button:disabled:hover,
.small-gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-gray-circle-button:hover {
  background-color: #9c9c9c;
}
.small-gray-circle-button:active {
  background-color: #7b7b7b;
}
.add-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.add-circle-button:disabled {
  background-color: gray;
}
.add-circle-button:disabled:hover,
.add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.small-add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  font-size: 11px;
  padding-top: 1px;
}
.small-add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-add-circle-button:disabled {
  background-color: gray;
}
.small-add-circle-button:disabled:hover,
.small-add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 12px;
  padding-top: 3px;
}
.search-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.search-circle-button:disabled {
  background-color: gray;
}
.search-circle-button:disabled:hover,
.search-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.search-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
  font-size: 16px;
  padding-top: 2px;
}
.close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.close-circle-button:disabled {
  background-color: gray;
}
.close-circle-button:disabled:hover,
.close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.close-circle-button:hover {
  background-color: #9c9c9c;
}
.close-circle-button:active {
  background-color: #7b7b7b;
}
.close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.small-close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
  font-size: 12px;
  padding-top: 1px;
}
.small-close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-close-circle-button:disabled {
  background-color: gray;
}
.small-close-circle-button:disabled:hover,
.small-close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-close-circle-button:hover {
  background-color: #9c9c9c;
}
.small-close-circle-button:active {
  background-color: #7b7b7b;
}
.small-close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.centered-message {
  position: absolute;
  width: 300px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  font-size: 28px;
  color: rgba(0, 122, 204, 0.6);
}
.hooks-page {
  margin: 20px;
  flex-grow: 1;
}
.hooks-page h3 {
  margin-bottom: 20px;
}
.hooks-page .action-bar {
  float: right;
  display: flex;
  flex-direction: row-reverse;
}
.hooks-page .action-bar > * {
  margin: 0 6px;
}
.hooks-page .action-bar .keypath-filter {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iNDhweCIgaGVpZ2h0PSI0OHB4IiB2aWV3Qm94PSIwIDAgNDggNDgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+U2VhcmNoIEljb24gPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlR3ZWVrLWljb25zIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IlNlYXJjaC1JY29uLSIgc3Ryb2tlPSIjNjk2OTY5IiBzdHJva2Utd2lkdGg9IjIiPiAgICAgICAgICAgIDxnIGlkPSJTZWFyY2gtaWNvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTUuMDAwMDAwLCAxNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTTE0LjMwNjcsNy4zNjg1OTM1IEMxNC4zMDY3LDExLjQzODUwNzEgMTEuMTAzNywxNC43MzY4NzggNy4xNTM3LDE0LjczNjg3OCBDMy4yMDI3LDE0LjczNjg3OCAtMC4wMDAzLDExLjQzODUwNzEgLTAuMDAwMyw3LjM2ODU5MzUgQy0wLjAwMDMsMy4yOTg2Nzk5IDMuMjAyNywwLjAwMDMwOTAyOTEyNiA3LjE1MzcsMC4wMDAzMDkwMjkxMjYgQzExLjEwMzcsMC4wMDAzMDkwMjkxMjYgMTQuMzA2NywzLjI5ODY3OTkgMTQuMzA2Nyw3LjM2ODU5MzUgTDE0LjMwNjcsNy4zNjg1OTM1IFoiIGlkPSJTdHJva2UtMSI+PC9wYXRoPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTcuMDcxNiwxNy40OTk1OTgzIEwxMi4zODk2LDEyLjY3NjY4MzgiIGlkPSJTdHJva2UtMyI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position-y: center;
  border-radius: 100px;
  border: none;
  width: 300px;
  text-indent: 35px;
  background-color: #ffffff;
}
.hooks-page .action-bar .keypath-filter:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.hooks-page .action-bar .keypath-filter:disabled {
  border-color: #bcbcbc;
}
.hooks-page .action-bar .keypath-filter:focus {
  border: none;
}
.hooks-page .action-bar .clear {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 8px 0;
  right: 10px;
  cursor: pointer;
}
.hooks-page .hooks-list {
  list-style-type: none;
}
.hooks-page .hooks-list > li {
  margin-bottom: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}
.hooks-page .hooks-list > li > * {
  margin: 0 6px;
  display: flex;
  flex-direction: column;
}
.hooks-page .hooks-list > li .actions-wrapper > button {
  margin-top: 7px;
}
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  background-color: #ce352c;
}
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button:disabled {
  background-color: gray;
}
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button:disabled:hover,
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.hooks-page .hooks-list > li .actions-wrapper .delete-hook-button:hover {
  background-color: #a42a23;
}
.hooks-page .hooks-list > li .field-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-top: 5px;
}
.hooks-page .hooks-list > li .field-wrapper .field-label {
  all: unset;
  font-size: 16px;
  margin: 0px 5px 0 0;
  color: gray;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding-bottom: 1px;
}
.hooks-page .hooks-list > li .field-wrapper .field-value {
  padding-bottom: 1px;
  font-size: 16px;
}
