.keys-page-container {
  display: flex;
  flex-grow: 1;
  background-color: #eee;
}
.keys-page-container .keys-list {
  display: flex;
  flex-basis: 400px;
  flex-direction: column;
  background-color: #414b53;
}
.keys-page-container .keys-list .keys-list-wrapper {
  display: flex;
  flex-grow: 1;
  min-height: 1px;
}
.keys-page-container .key-page {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-grow: 1;
  flex-shrink: 0;
}
.keys-page-container .add-button-wrapper {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333b41;
}
.keys-page-container .add-button-wrapper .add-key-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  height: 34px;
  border-radius: 17px;
  width: 200px;
}
.keys-page-container .add-button-wrapper .add-key-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.keys-page-container .add-button-wrapper .add-key-button:disabled {
  background-color: gray;
}
.keys-page-container .add-button-wrapper .add-key-button:disabled:hover,
.keys-page-container .add-button-wrapper .add-key-button:disabled:active {
  cursor: default;
  background-color: gray;
}
