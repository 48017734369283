.multi-source-combo-box-suggestions {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid lightgray;
  z-index: 5;
  background-color: white;
}
.multi-source-combo-box-suggestions .source-select {
  display: flex;
  border-bottom: 1px solid lightgray;
  color: gray;
  text-align: center;
}
.multi-source-combo-box-suggestions .source-select .source-item {
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid lightgray;
  padding: 3px;
  cursor: pointer;
}
.multi-source-combo-box-suggestions .source-select .source-item:last-child {
  border-right: none;
}
.multi-source-combo-box-suggestions .source-select .source-item.active {
  color: white;
  background: gray;
  cursor: default;
}
