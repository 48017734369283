.matcher-operator {
  margin: 0 5px;
  display: inline-block;
  display: flex;
}
.matcher-operator .override-typeahead .override-input {
  flex-grow: 1;
}
.matcher-operator .override-typeahead .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 80px;
}
.matcher-operator .override-typeahead .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.matcher-operator .override-typeahead .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li a,
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li a:first-child,
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.matcher-operator .override-typeahead .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.matcher-operator .override-typeahead .bootstrap-typeahead {
  flex-grow: 1;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 80px;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.matcher-operator .override-typeahead .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li a,
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li a:first-child,
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.matcher-operator .override-typeahead .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.matcher-operator .override-input {
  flex-grow: 1;
}
.matcher-operator .override-input .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 80px;
}
.matcher-operator .override-input .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.matcher-operator .override-input .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.matcher-operator .override-dropdown .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.matcher-operator .override-dropdown .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.matcher-operator .override-dropdown .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.matcher-operator .override-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.matcher-operator .override-dropdown .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.matcher-operator .override-dropdown .dropdown-menu li a,
.matcher-operator .override-dropdown .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.matcher-operator .override-dropdown .dropdown-menu li a:first-child,
.matcher-operator .override-dropdown .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.matcher-operator .override-dropdown .dropdown-menu .disabled {
  display: none;
}
.matcher-operator .override-dropdown .dropdown-menu .active {
  background-color: #dfdfdf;
}
.matcher-operator .bootstrap-typeahead {
  flex-grow: 1;
}
.matcher-operator .bootstrap-typeahead .bootstrap-typeahead-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  width: 80px;
}
.matcher-operator .bootstrap-typeahead .bootstrap-typeahead-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.matcher-operator .bootstrap-typeahead .bootstrap-typeahead-input:disabled {
  border-color: #bcbcbc;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu {
  position: absolute;
  max-height: 350px;
  min-width: 80px;
  overflow-y: auto;
  width: auto;
  z-index: 5;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li {
  background-color: #f4f4f4;
  min-height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li:last-child {
  border-bottom-width: 1px;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #dfdfdf;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li:active {
  background-color: #d1d1d1;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li a,
.matcher-operator .bootstrap-typeahead .dropdown-menu li button {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu li a:first-child,
.matcher-operator .bootstrap-typeahead .dropdown-menu li button:first-child {
  padding: 10px;
  padding-right: 30px;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu .disabled {
  display: none;
}
.matcher-operator .bootstrap-typeahead .dropdown-menu .active {
  background-color: #dfdfdf;
}
.matcher-operator .bootstrap-typeahead-input {
  text-align: center;
}
