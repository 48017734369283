.jpad-editor-container .jpad-settings {
  display: flex;
  float: right;
  padding: 20px 25px;
}
.jpad-editor-container .jpad-settings .default-value .bootstrap-typeahead .dropdown-menu {
  min-width: 150px;
}
.jpad-editor-container .vertical-separator {
  width: 1px;
  background-color: lightgray;
  height: 30px;
  margin: 0 15px 0 12px;
}
