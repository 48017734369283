.key-edit-page {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
}
.key-edit-page .key-viewer-container-fieldset {
  display: flex;
  flex-grow: 1;
  border: none;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container {
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .new-key-input-wrapper {
  display: flex;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .new-key-input-wrapper .vertical-separator {
  width: 1px;
  background-color: lightgray;
  height: 30px;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-container {
  min-height: 34px;
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-form {
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  font-size: 22px;
  font-weight: 300;
  margin-right: 20px;
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-input:disabled {
  border-color: #bcbcbc;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .key-name-input {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  font-size: 22px;
  font-weight: 300;
  margin-right: 20px;
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .key-name-input:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .key-name-input:disabled {
  border-color: #bcbcbc;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .display-name-text {
  font-size: 22px;
  display: flex;
  flex-grow: 1;
}
.key-edit-page .key-viewer-container-fieldset .key-viewer-container .key-main-input .validation-message {
  font-size: 13px;
}
.key-edit-page .key-viewer-container-fieldset .sticky-key-header {
  position: absolute;
  height: 72px;
  width: 100%;
  display: flex;
  padding: 16px;
  padding-top: 22px;
  background-color: white;
  box-shadow: 0 5px 1px 0 rgba(0, 0, 0, 0.15);
  z-index: 2;
  box-sizing: border-box;
}
.key-edit-page .key-viewer-container-fieldset .key-header {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  margin: 16px;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-top: 16px;
  padding: 15px;
  box-sizing: border-box;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-header-and-modification-wrapper {
  display: flex;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-header-and-modification-wrapper .modification-data {
  float: right;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-full-path {
  color: #a5a5a5;
  font-size: 14px;
  margin-left: 5px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-full-path .actual-path {
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
  user-select: text;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-description-tags-hooks-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 10px;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-description-tags-hooks-wrapper .key-description-wrapper .description-input {
  resize: none;
  font-size: 14px;
  color: #515c66;
  min-width: 400px;
  max-height: 120px;
  outline: none;
  box-sizing: border-box;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-description-tags-hooks-wrapper .key-tags-wrapper {
  display: flex;
  width: 0;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  max-height: 120px;
  overflow-y: auto;
  padding-top: 0;
  border-left: 1px solid lightgray;
  padding-left: 20px;
  margin-left: 20px;
  max-width: 400px;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-description-tags-hooks-wrapper .key-hooks-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-height: 120px;
  padding-top: 0;
  border-left: 1px solid lightgray;
  padding-left: 20px;
  margin-left: 20px;
}
.key-edit-page .key-viewer-container-fieldset .key-header .key-meta-container .key-description-tags-hooks-wrapper .key-hooks-wrapper > button {
  margin-bottom: 5px;
  width: 120px;
}
.key-edit-page .key-viewer-container-fieldset .key-rules-editor {
  display: flex;
  flex-grow: 1;
  margin: 16px;
  margin-top: 0;
}
.key-edit-page .key-viewer-container-fieldset .key-rules-editor.sticky {
  z-index: 1;
}
