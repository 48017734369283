.rodal-container {
  color: #555;
  line-height: 1.42857143;
}
.rodal-container.resizable .rodal-dialog {
  resize: both;
  overflow: auto;
  min-width: 500px;
  min-height: 400px;
}
.rodal-container .rodal-header {
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}
.rodal-container .rodal-body {
  padding-top: 15px;
  font-size: 14px;
}
.rodal-container .rodal-button-container {
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.rodal-container .rodal-button-container button {
  min-width: 75px;
  margin: 0 5px;
  padding: 4px 15px;
  border-radius: 3px;
  transition: background 0.2s;
  border: 1px solid #03a9f4;
}
.rodal-container .rodal-button-container .rodal-confirm-btn,
.rodal-container .rodal-button-container .rodal-save-btn,
.rodal-container .rodal-button-container .auto-partition-btn {
  color: #fff;
  background: #03a9f4;
}
.rodal-container .rodal-button-container .rodal-confirm-btn:hover,
.rodal-container .rodal-button-container .rodal-save-btn:hover,
.rodal-container .rodal-button-container .auto-partition-btn:hover {
  background: #0098e3;
}
.rodal-container .rodal-button-container .rodal-confirm-btn:disabled,
.rodal-container .rodal-button-container .rodal-save-btn:disabled,
.rodal-container .rodal-button-container .auto-partition-btn:disabled {
  background: #dddddd;
  border: 1px solid #cccccc;
  color: #888888;
}
.rodal-container .rodal-button-container .rodal-cancel-btn,
.rodal-container .rodal-button-container .reset-partitions-btn {
  color: #03a9f4;
  background: #fff;
}
.rodal-container .rodal-button-container .rodal-cancel-btn:hover,
.rodal-container .rodal-button-container .reset-partitions-btn:hover {
  background: #fafafa;
}
