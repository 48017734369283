.key-page-message {
  position: absolute;
  width: 300px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  font-size: 28px;
  color: rgba(0, 122, 204, 0.6);
}
