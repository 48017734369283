.app .menu {
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
  justify-content: flex-end;
}
.app .menu li {
  list-style: none;
}
.app .menu .menu-item {
  display: flex;
  border-bottom: 4px solid transparent;
  padding: 0 10px;
  color: white;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.app .menu .menu-item span {
  margin-right: 24px;
  transition: all 0.2s ease-in-out;
}
.app .menu .menu-item:visited,
.app .menu .menu-item:link {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 66px;
  text-decoration: none;
  font-size: 22px;
  font-weight: 300;
  color: white;
}
.app .menu .menu-item.selected-location-path {
  border-bottom: 4px solid white;
}
.app .menu .menu-item:hover {
  cursor: pointer;
  border-bottom: 4px solid white;
}
.app .menu .menu-item:hover.selected-location-path {
  border-bottom: 4px solid white;
}
.app .menu .menu-item:active {
  border-bottom: 4px solid white;
}
.app .header {
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  position: fixed;
  border-bottom: 1px solid lightgray;
  align-items: center;
  background-color: #009fda;
}
.app .header .logo {
  margin: 5px 0 0 20px;
  width: 140px;
}
.app .header .user-bar {
  display: flex;
  position: absolute;
  background-color: white;
  right: 0;
  top: 0;
  border: 2px solid yellow;
}
.app .header .user-bar img {
  display: block;
}
.app .page {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
