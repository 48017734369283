.editable-text-container {
  border: 1px solid transparent;
}
.editable-text-container .editable-text-input {
  color: #515c66;
  box-sizing: border-box;
}
.editable-text-container .editable-text-value {
  color: #515c66;
  margin-left: 4px;
}
