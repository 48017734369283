.panel {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
  box-sizing: content-box;
  margin: 20px;
  display: block;
}
.metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.metro-button:disabled {
  background-color: gray;
}
.metro-button:disabled:hover,
.metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
  background-color: transparent;
  color: gray;
}
.transparent-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.transparent-metro-button:disabled {
  background-color: gray;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.transparent-metro-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.transparent-metro-button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.transparent-metro-button:disabled {
  background-color: transparent;
}
.transparent-metro-button:disabled:hover,
.transparent-metro-button:disabled:active {
  cursor: default;
  background-color: transparent;
}
.small-metro-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
}
.small-metro-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-metro-button:disabled {
  background-color: gray;
}
.small-metro-button:disabled:hover,
.small-metro-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-metro-textbox {
  display: inline-block;
  border: 0 none;
  border-bottom: 1px solid rgba(0, 122, 204, 0.5);
  min-height: 28px;
  width: 150px;
  padding: 0 4px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  color: #515c66;
  outline: none;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.2s ease-in-out;
  height: 16px;
  font-size: 12px;
}
.small-metro-textbox:focus {
  border-bottom: 2px solid rgba(0, 122, 204, 0.8);
}
.small-metro-textbox:disabled {
  border-color: #bcbcbc;
}
.circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
}
.circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.circle-button:disabled {
  background-color: gray;
}
.circle-button:disabled:hover,
.circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
}
.small-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-circle-button:disabled {
  background-color: gray;
}
.small-circle-button:disabled:hover,
.small-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-button {
  background-color: #bcbcbc;
}
.gray-button:hover {
  background-color: #9c9c9c;
}
.gray-button:active {
  background-color: #7b7b7b;
}
.gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
}
.gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.gray-circle-button:disabled {
  background-color: gray;
}
.gray-circle-button:disabled:hover,
.gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.gray-circle-button:hover {
  background-color: #9c9c9c;
}
.gray-circle-button:active {
  background-color: #7b7b7b;
}
.small-gray-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
}
.small-gray-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-gray-circle-button:disabled {
  background-color: gray;
}
.small-gray-circle-button:disabled:hover,
.small-gray-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-gray-circle-button:hover {
  background-color: #9c9c9c;
}
.small-gray-circle-button:active {
  background-color: #7b7b7b;
}
.add-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 2px;
}
.add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.add-circle-button:disabled {
  background-color: gray;
}
.add-circle-button:disabled:hover,
.add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.small-add-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  font-size: 11px;
  padding-top: 1px;
}
.small-add-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-add-circle-button:disabled {
  background-color: gray;
}
.small-add-circle-button:disabled:hover,
.small-add-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-add-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E905';
}
.search-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  font-size: 12px;
  padding-top: 3px;
}
.search-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.search-circle-button:disabled {
  background-color: gray;
}
.search-circle-button:disabled:hover,
.search-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.search-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E906';
}
.close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  background-color: #bcbcbc;
  font-size: 16px;
  padding-top: 2px;
}
.close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.close-circle-button:disabled {
  background-color: gray;
}
.close-circle-button:disabled:hover,
.close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.close-circle-button:hover {
  background-color: #9c9c9c;
}
.close-circle-button:active {
  background-color: #7b7b7b;
}
.close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.small-close-circle-button {
  vertical-align: top;
  background-color: #00aeef;
  height: 28px;
  border: 0;
  padding: 0 20px;
  color: white;
  outline: 0;
  padding-bottom: 2px;
  border-radius: 14px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0 0 3px;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  font-size: 10px;
  padding-bottom: 4px;
  background-color: #bcbcbc;
  font-size: 12px;
  padding-top: 1px;
}
.small-close-circle-button:hover {
  background-color: #029cd5;
  cursor: pointer;
}
.small-close-circle-button:disabled {
  background-color: gray;
}
.small-close-circle-button:disabled:hover,
.small-close-circle-button:disabled:active {
  cursor: default;
  background-color: gray;
}
.small-close-circle-button:hover {
  background-color: #9c9c9c;
}
.small-close-circle-button:active {
  background-color: #7b7b7b;
}
.small-close-circle-button:after {
  font-family: 'tweekIcons';
  content: '\E900';
}
.centered-message {
  position: absolute;
  width: 300px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  font-size: 28px;
  color: rgba(0, 122, 204, 0.6);
}
.policies-page {
  margin: 20px;
  flex-grow: 1;
}
.policies-page h3 {
  margin-bottom: 20px;
}
.policies-page *[role='tablist'] {
  list-style: none;
  display: flex;
}
.policies-page *[role='tabpanel'] {
  padding: 10px;
  background-color: white;
  border: 1px solid lightgray;
  border-collapse: collapse;
  height: 600px;
  position: relative;
}
.policies-page *[role='tabpanel']:not(.react-tabs__tab-panel--selected) {
  display: none;
}
.policies-page *[role='tabpanel'] .save-button {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 1;
}
.policies-page *[role='tab'] {
  color: #515c66;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px 32px;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgray;
  height: 46px;
  font-size: 14px;
}
.policies-page *[role='tab']:hover {
  cursor: pointer;
}
.policies-page *[role='tab'][aria-selected='true'] {
  border-bottom: 3px solid rgba(0, 122, 204, 0.5);
  background-color: white;
}
