.rule-container {
  display: flex;
  padding: 10px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.rule-container.default-value {
  background-color: #ebebeb;
}
.rule-container .rule-partial-title {
  color: gray;
  font-size: 13px;
  margin-bottom: 5px;
}
.rule-container .conditions {
  flex-basis: 60%;
  border-right: 1px solid lightgray;
  padding-right: 5px;
}
.rule-container .values {
  margin-left: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
