.key-rules-editor-container {
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  border: none;
}
.key-rules-editor-container .tab-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.key-rules-editor-container .tab-container .tab-header {
  color: #515c66;
  display: inline-block;
  box-sizing: border-box;
  padding: 12px 32px;
  height: 46px;
}
.key-rules-editor-container .tab-container .tab-header .tab-icon {
  float: left;
  font-family: 'tweekIcons';
}
.key-rules-editor-container .tab-container .tab-header .key-definition-tab-icon {
  float: left;
  font-family: 'tweekIcons';
  margin-top: 2px;
  font-size: 17px;
}
.key-rules-editor-container .tab-container .tab-header .key-source-tab-icon {
  float: left;
  font-family: 'tweekIcons';
  font-size: 20px;
}
.key-rules-editor-container .tab-container .tab-header .tab-title {
  font-size: 14px;
  margin-left: 10px;
}
.key-rules-editor-container .tab-container .tab-header .tab-title:hover {
  cursor: pointer;
}
.key-rules-editor-container .tab-content {
  flex-grow: 1;
  border: 1px solid lightgray;
  background-color: white;
}
.key-rules-editor-container .tab-content:not(.react-tabs__tab-panel--selected) {
  display: none;
}
.react-tabs__tab-list {
  display: block;
}
.react-tabs__tab-list .tab-header {
  display: flex;
  border: 1px solid lightgray;
  border-right-width: 0;
  border-bottom-width: 3px;
  border-radius: 5px 5px 0 0;
  padding: 10px 30px;
  outline: none;
  background-color: #ececec;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.react-tabs__tab-list .tab-header:last-child {
  border-right: 1px solid lightgray;
}
.react-tabs__tab-list .tab-header:hover {
  border-bottom: 3px solid rgba(0, 122, 204, 0.2);
  cursor: pointer;
}
.react-tabs__tab-list .tab-header:active {
  border-bottom: 3px solid rgba(0, 122, 204, 0.6);
}
.react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: 3px solid rgba(0, 122, 204, 0.5);
  background-color: white;
}
.react-tabs__tab-list .react-tabs__tab--selected:hover {
  border-bottom: 3px solid rgba(0, 122, 204, 0.5);
  cursor: default;
}
.react-tabs__tab-list .react-tabs__tab--selected:active {
  border-bottom: 3px solid rgba(0, 122, 204, 0.5);
}
